import { initialiseToggles } from "./toggles";
import {
  addVisualisationTabEventListeners,
  initialiseVisualisationTabsGroups,
} from "./visualisationTabs";
import { addComponentToggleEventListeners } from "./components";
import { initialiseIndicatorComponentTabs } from "./componentTabs";

const indicatorComponentTabs = document.querySelectorAll(
  ".c-indicator-component-tab"
);
const indicatorComponentTabpanels = document.querySelectorAll(
  ".c-indicator-component-tabpanel"
);

initialiseIndicatorComponentTabs(
  indicatorComponentTabs,
  indicatorComponentTabpanels
);

const toggles = [
  {
    toggleClass: "c-navigation__toggle",
    controlledItemClass: "c-navigation__items",
  },
  {
    toggleClass: "c-dropdown__toggle",
    controlledItemClass: "c-dropdown__items",
  },
  {
    toggleClass: "c-search__toggle",
    controlledItemClass: "c-search__form",
  },
];

toggles.forEach((toggle) => {
  initialiseToggles(toggle.toggleClass, toggle.controlledItemClass);
});

const tabs = document.querySelectorAll("[role=tab]");
tabs.forEach((tab) => addVisualisationTabEventListeners(tab));
initialiseVisualisationTabsGroups(tabs);

const componentToggles = document.querySelectorAll("[id$='component-toggle']");
componentToggles.forEach((componentToggle) =>
  addComponentToggleEventListeners(componentToggle)
);
