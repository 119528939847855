const toggleBooleanAttribute = (element, attribute) => {
  const currentState = element.getAttribute(attribute);
  if (currentState === "true") {
    element.setAttribute(attribute, false);
  } else {
    element.setAttribute(attribute, true);
  }
};

/**
 *
 * @param {Event} e A "click" event
 */
const componentToggleClickEventListener = (clickEvent) => {
  const componentToggle = clickEvent.target;
  const selectedRegionId = componentToggle.getAttribute("aria-controls");
  const selectedRegion = document.getElementById(selectedRegionId);
  selectedRegion.classList.toggle("c-indicator-component__region--closed");
  const selectedIcon = componentToggle.querySelector(
    ".c-indicator-component__icon"
  );
  selectedIcon.classList.toggle("c-indicator-component__icon--closed");
  toggleBooleanAttribute(componentToggle, "aria-expanded");
};

/**
 * Add event listeners to a tab
 *
 * @param {HTMLElement} tab A tab
 */
const addComponentToggleEventListeners = (componentToggle) => {
  componentToggle.addEventListener("click", componentToggleClickEventListener);
};

export { addComponentToggleEventListeners };
