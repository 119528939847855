const initialiseToggles = (toggleClass, controlledItemClass) => {
  const toggles = document.querySelectorAll(`.${toggleClass}`);
  const clickedToggleClass = `${toggleClass}--clicked`;
  const controlledItems = document.querySelectorAll(`.${controlledItemClass}`);
  const closedClass = `${controlledItemClass}--closed`;

  toggles.forEach((toggle) => {
    const controlledItemsId = toggle.getAttribute("aria-controls");
    const controlledItem = document.getElementById(controlledItemsId);

    toggle.addEventListener("click", () => {
      controlledItems.forEach((_controlledItem) => {
        if (
          _controlledItem !== controlledItem &&
          !_controlledItem.classList.contains(closedClass)
        ) {
          _controlledItem.classList.add(closedClass);
        }
      });
      controlledItem.classList.toggle(closedClass);

      toggles.forEach((_toggle) => {
        if (
          _toggle !== toggle &&
          _toggle.classList.contains(clickedToggleClass)
        ) {
          _toggle.classList.remove(clickedToggleClass);
        }
      });
      toggle.classList.toggle(clickedToggleClass);
    });
  });
};

export { initialiseToggles };
