const uncheckRadioButtonClass = "c-radio-button__inner-circle--unchecked";

const getRadioButtonInnerCircle = async (radioButton) => {
  return radioButton.querySelector(".c-radio-button__inner-circle");
};

const uncheckRadioButtons = async (radioButtons) => {
  radioButtons.forEach(async (radioButton) => {
    const radioButtonInnerCircle = await getRadioButtonInnerCircle(radioButton);
    radioButtonInnerCircle.classList.add(uncheckRadioButtonClass);
  });
};

const checkSelectedRadioButton = async (radioButton) => {
  const radioButtonInnerCircle = await getRadioButtonInnerCircle(radioButton);
  radioButtonInnerCircle.classList.remove(uncheckRadioButtonClass);
};

const initialiseRadioButtons = async (radioButtons) => {
  uncheckRadioButtons(radioButtons);
  checkSelectedRadioButton(radioButtons[0]);
};

const hiddenIndicatorComponentTabpanelClass =
  "c-indicator-component-tabpanel--closed";

const hideIndicatorComponentTabpanels = async (indicatorComponentTabpanels) => {
  indicatorComponentTabpanels.forEach(async (indicatorComponentTabpanel) => {
    indicatorComponentTabpanel.classList.add(
      hiddenIndicatorComponentTabpanelClass
    );
  });
};

const showSelectedIndicatorComponentTabpanel = async (
  indicatorComponentTabpanel
) => {
  indicatorComponentTabpanel.classList.remove(
    hiddenIndicatorComponentTabpanelClass
  );
};

const initialiseIndicatorComponentTabpanels = async (
  indicatorComponentTabpanels
) => {
  hideIndicatorComponentTabpanels(indicatorComponentTabpanels);
  showSelectedIndicatorComponentTabpanel(indicatorComponentTabpanels[0]);
};
const addIndicatorComponentTabClickEventListener = async (
  indicatorComponentTabs,
  indicatorComponentTabpanels
) => {
  indicatorComponentTabs.forEach(async (indicatorComponentTab) => {
    indicatorComponentTab.addEventListener("click", (event) => {
      uncheckRadioButtons(indicatorComponentTabs);
      hideIndicatorComponentTabpanels(indicatorComponentTabpanels);
      checkSelectedRadioButton(event.target);
      const selectedIndicatorComponentTabpanel = document.getElementById(
        event.target.getAttribute("aria-controls")
      );
      showSelectedIndicatorComponentTabpanel(
        selectedIndicatorComponentTabpanel
      );
    });
  });
};

export const initialiseIndicatorComponentTabs = async (
  indicatorComponentTabs,
  indicatorComponentTabpanels
) => {
  initialiseRadioButtons(indicatorComponentTabs);
  initialiseIndicatorComponentTabpanels(indicatorComponentTabpanels);
  addIndicatorComponentTabClickEventListener(
    indicatorComponentTabs,
    indicatorComponentTabpanels
  );
};
