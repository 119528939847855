// See https://www.w3.org/WAI/ARIA/apg/patterns/tabpanel/

const closedTabClass = "c-indicator-component-visualisation-tab--closed";

/**
 * Hide all elements with "tab" role
 */
const deselectVisualisationTabs = (tabGroup) => {
  const tabs = document.querySelectorAll(
    `[data-tabgroup=${tabGroup}][role="tab"]`
  );
  tabs.forEach((tab) => {
    tab.classList.add(closedTabClass);
    tab.setAttribute("aria-selected", "false");
    tab.setAttribute("tabindex", "-1");
  });
};

/**
 * Display the clicked "tab"
 *
 * @param {HTMLElement} selectedTab The clicked "tab" element
 */
const selectVisualisationTab = (selectedTab) => {
  selectedTab.classList.remove(closedTabClass);
  selectedTab.setAttribute("aria-selected", "true");
  selectedTab.removeAttribute("tabindex");
};

/**
 * Make all elements under the parent unreachable by sequential keyboard navigation
 *
 * @param {HTMLElement} parent The parent element
 */
const hideFocusableDescendants = (parent) => {
  parent.querySelectorAll("*").forEach((element) => {
    element.setAttribute("tabindex", "-1");
  });
};

/**
 * Stop all elements under the parent from being unreachable by sequential keyboard navigation
 *
 * @param {HTMLElement} parent The parent element
 */
const unhideFocusableDescendants = (parent) => {
  parent.querySelectorAll("*").forEach((element) => {
    element.removeAttribute("tabindex");
  });
};

const closedPanelClass = "c-indicator-component-visualisation-tabpanel--closed";

/**
 * Hide all elements with "tabpanel" role
 */
const deselectVisualisationPanels = (panelGroup) => {
  const panels = document.querySelectorAll(
    `[data-tabgroup=${panelGroup}][role="tabpanel"]`
  );
  Array.from(panels).forEach((panel) => {
    panel.classList.add(closedPanelClass);
    panel.setAttribute("aria-hidden", "true");
    hideFocusableDescendants(panel);
  });
};

/**
 * Display the "tab panel" controlled by the clicked "tab"
 *
 * @param {HTMLElement} selectedTab The clicked "tab" element
 */
const selectControlledVisualisationPanel = (selectedTab) => {
  const controlledPanelId = selectedTab.getAttribute("aria-controls");
  const controlledPanel = document.getElementById(controlledPanelId);
  controlledPanel.classList.remove(closedPanelClass);
  controlledPanel.setAttribute("aria-hidden", "false");
  unhideFocusableDescendants(controlledPanel);
};

const initialiseVisualisationTabs = (tab) => {
  const selectedTabGroup = tab.dataset.tabgroup;
  deselectVisualisationTabs(selectedTabGroup);
  deselectVisualisationPanels(selectedTabGroup);
  selectVisualisationTab(tab);
  selectControlledVisualisationPanel(tab);
};

/**
 *
 * @param {Event} e A "click" event
 */
const visualisationTabClickEventListener = (e) => {
  const selectedTab = e.target;
  initialiseVisualisationTabs(selectedTab);
};

/**
 *
 * @param {Event} e A "keydown" event
 */
const visualisationTabKeydownEventListener = (e) => {
  switch (e.key) {
    case "ArrowRight":
      document.activeElement.nextElementSibling.focus();
      break;
    case "ArrowLeft":
      document.activeElement.previousElementSibling.focus();
      break;
    case "Home":
      e.preventDefault();
      document.activeElement.parentElement.firstElementChild.focus();
      break;
    case "End":
      e.preventDefault();
      document.activeElement.parentElement.lastElementChild.focus();
      break;
  }
};

/**
 * Add event listeners to a tab
 *
 * @param {HTMLElement} tab A tab
 */
const addVisualisationTabEventListeners = (tab) => {
  tab.addEventListener("click", visualisationTabClickEventListener);
  tab.addEventListener("keydown", visualisationTabKeydownEventListener);
};

const groupVisualisationTabs = (nodeList, dataAttribute) => {
  return Array.from(nodeList).reduce((accumulator, currentValue) => {
    (accumulator[currentValue.dataset[dataAttribute]] =
      accumulator[currentValue.dataset[dataAttribute]] || []).push(
      currentValue
    );
    return accumulator;
  }, {});
};

const initialiseVisualisationTabsGroups = (tabs) => {
  const groups = groupVisualisationTabs(tabs, "tabgroup");

  Object.values(groups).forEach((tabs) => {
    tabs.forEach((tab, index) => {
      if (index === 0) {
        console.log("initialising tab", tab);
        initialiseVisualisationTabs(tab);
      }
    });
  });
};

export {
  addVisualisationTabEventListeners,
  hideFocusableDescendants,
  initialiseVisualisationTabsGroups,
};
